<script>
export default {
  name: "MonthlyReports",
  data () {
    return {
      reports: [],
      hideZero: false,
      itemsPerPage: -1,
      headers: [
        { value: 'generatedDate', text: 'Data generazione' },
        { value: 'yearText', text: 'Anno/mese' },
        { value: 'invoiceNumber', text: 'Fattura n.' },
        { value: 'grossStr', text: 'Incassato' },
        { value: 'feesStr', text: 'Costo Apollo' },
        { value: 'feesVatStr', text: 'IVA Apollo' },
        { value: 'actions', text: 'Azioni' },
      ]
    }
  },
  computed: {
    reportsComputed () {
      return this.reports.filter(i => this.hideZero ? i.gross > 0 : true)
    }
  },
  mounted () {
    this.getMonthlyReports()
  },
  methods: {
    async getMonthlyReports () {
      try {
        const r = await this.$http.reports('v2/monthly-reports')

        r.data.forEach(i => {
          i.generatedDate = this.formatDate(i.generatedDate)
          i.yearText = `${i.year}/${i.month}`

          i.grossStr = this.formatMoney(i.gross)
          i.feesStr = this.formatMoney(i.fees)
          i.feesVatStr = this.formatMoney(i.feesVat)
        })

        this.reports = r.data
      } catch (e) {
      }
    },
    async downloadCsv (item) {
      const res = await this.$http.reports(`v2/monthly-reports/${item.id}/transactions-csv`)

      const { year, month } = item

      // Create a link element
      const link = document.createElement('a')
      link.href = res.data
      link.target = '_blank'
      link.download = `tranazioni-${year}-${month}.csv` // Filename for the downloaded CSV

      // Append the link to the body and trigger the download
      document.body.appendChild(link)
      link.click()

      // Remove the link after download
      document.body.removeChild(link)
    }
  }
}
</script>

<template>
  <div>
    <h1>Report mensili</h1>

    <v-alert
        outlined prominent
        type="info" border="left"
    >
      <b>Per saperne di più</b> su come funzionano i report mensili e le dichiarazioni degli incassi, leggi la documentazione
      dedicata cliccando il pulsante qui a destra.

      <div v-if="$vuetify.breakpoint.smAndDown" class="mt-4">
        <v-btn
            color="info" outlined
            href="https://foodytek.notion.site/APOLLO-by-FoodyTek-Contabilit-incassi-ricariche-tramite-Stripe-b1a9284b333a407e97cbae5476c85216?pvs=4"
            target="_blank"
            :small="$vuetify.breakpoint.xs"
        >
          Vai alla documentazione
        </v-btn>
      </div>

      <template v-slot:append>
        <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            color="info" outlined
            href="https://foodytek.notion.site/APOLLO-by-FoodyTek-Contabilit-incassi-ricariche-tramite-Stripe-b1a9284b333a407e97cbae5476c85216?pvs=4"
            target="_blank"
            class="ml-3"
        >
          Vai alla documentazione
        </v-btn>
      </template>
    </v-alert>

    <v-alert
        v-if="!$store.getters.isClientAccountant"
        outlined prominent
        type="info" border="left"
    >
      <b>Sai che puoi invitare il tuo commercialista a vedere i report mensili?</b> Clicca qui per invitarlo a registrarsi alla
      piattaforma Apollo e accedere autonomamente a quello di cui ha bisogno!

      <div v-if="$vuetify.breakpoint.smAndDown" class="mt-4">
        <v-btn
            color="info" outlined
            to="/client-users?invite=accountant"
            class="ml-3"
            :small="$vuetify.breakpoint.xs"
        >
          Invita commercialista
        </v-btn>
      </div>

      <template v-slot:append>
        <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            color="info" outlined
            to="/client-users?invite=accountant"
            class="ml-3"
        >
          Invita commercialista
        </v-btn>
      </template>
    </v-alert>

    <v-checkbox
        v-model="hideZero"
        label="Nascondi report senza transazioni (0 € incassato)"
    />

    <v-card outlined class="mt-3">
      <v-data-table
          hide-default-footer
          :items="reportsComputed"
          :headers="headers"
          :items-per-page="itemsPerPage"
          item-key="to"
          disable-sort
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :disabled="item.fees === 0"
                  @click="downloadCsv(item)"
              >
                <v-icon>fa-file-csv</v-icon>
              </v-btn>
            </template>
            <span>Scarica file CSV delle transazioni del mese</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
