<template src="./Administrators.html"/>

<script>
import VTooltip from 'vuetify/lib/components/VTooltip/VTooltip'
import InviteUserToPlatformModal from "@/components/modals/commons/InviteUserToPlatformModal.vue";

export default {

  components: {
    InviteUserToPlatformModal,
    VTooltip
  },
  name: 'Administrators',
  data () {
    return {
      registrationLinkModal: false,
      search: '',
      adminUsers: [],
      registrationTokens: []
    }
  },
  computed: {
    registrationTokensTableFields () {
      return [
        { value: 'email', text: this.$t('administrators.userEmail') },
        { value: 'crud', text: '', align: 'center', sortable: false }
      ]
    },
    tableFields () {
      return [
        { value: 'email', text: this.$t('administrators.userEmail') },
        { value: 'name', text: this.$t('administrators.userName') },
        { value: 'surname', text: this.$t('administrators.userSurname') },
        { value: 'crud', text: '', align: 'center', sortable: false }
      ]
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getRegistrationTokens()
      this.getAdminUsers()
    },
    reload () {
      setTimeout(this.init, 200)
    },
    async getAdminUsers () {
      try {
        const response = await this.$http.auth.get('queries/users/role/admin')
        this.adminUsers = response.data
      } catch (e) {
      }
    },
    async getRegistrationTokens () {
      try {
        const response = await this.$http.auth.get('invitations/admins')
        this.registrationTokens = response.data
      } catch (e) {
      }
    },
    async downgradeUser (item) {
      try {
        await this.$dialog.confirmation.deletion(this.$t('user.downgradeMessage', { email: item.email }), this.$t('user.downgrade'))
        await this.$http.auth.delete(`users/${item.id}/downgrade`)
        this.reload()
      } catch (e) {
      }
    },
    async revokeToken (item) {
      try {
        await this.$dialog.confirmation.deletion(this.$t('user.revokeTokenMessage', { email: item.email }), this.$t('user.revoke'))
        await this.$http.auth.delete('invitations/' + item.id)
        this.reload()
      } catch (e) {
      }
    }
  }
}
</script>

<style scoped>

</style>
