var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"admins"}},[(_vm.registrationTokens && _vm.registrationTokens.length)?_c('v-card',{staticClass:"mt-8 elevation-8 mx-auto mb-7",staticStyle:{"width":"90%"}},[_c('v-card-title',{staticClass:"d-block text-center pt-6 pb-5 text-h4"},[_vm._v(" Registration links sent ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","items":_vm.registrationTokens,"headers":_vm.registrationTokensTableFields,"item-key":"item.id"},scopedSlots:_vm._u([{key:"item.crud",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"pa-5",attrs:{"small":"","fab":"","icon":"","color":"red"},on:{"click":function($event){return _vm.revokeToken(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-trash")])],1)]}}],null,false,2476369523)})],1)],1):_vm._e(),_c('v-card',{staticClass:"mt-8 elevation-8 mx-auto mb-7",staticStyle:{"width":"90%"}},[_c('v-card-title',{staticClass:"d-block text-center pt-6 pb-5 text-h4"},[_vm._v(" "+_vm._s(_vm.$t('administrators.title'))+" ")]),_c('v-card-text',[_c('div',{staticClass:"d-flex align-center mb-4 mx-5"},[_c('v-text-field',{attrs:{"append-icon":"fa-search","label":_vm.$t('commons.search'),"single-line":"","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-6",attrs:{"color":"green","dark":""},domProps:{"textContent":_vm._s(_vm.$t('commons.create'))},on:{"click":function($event){_vm.registrationLinkModal = true}}})],1),_c('v-data-table',{attrs:{"items":_vm.adminUsers,"headers":_vm.tableFields,"search":_vm.search,"item-key":"item.id"},on:{"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.crud",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-5",attrs:{"small":"","fab":"","icon":"","color":"red"},on:{"click":function($event){return _vm.downgradeUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-angle-double-down")])],1)]}}],null,true)},[_c('span',[_vm._v("Downgrade "+_vm._s(item.name)+" to consumer")])])]}}])})],1)],1),_c('invite-user-to-platform-modal',{attrs:{"specific-role":"admin"},on:{"close":_vm.reload},model:{value:(_vm.registrationLinkModal),callback:function ($$v) {_vm.registrationLinkModal=$$v},expression:"registrationLinkModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }