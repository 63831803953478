<template>
  <action-modal
      v-model="modalVisible"
      :data="obj"
      :cancel-btn-text="$t('commons.cancel')"
      :loading-text="$t('commons.loading')"
      :submit-title="$t('registrationLinkModal.title')"
      :submit-success-message="$t('registrationLinkModal.successfulMessage')"
      :submit-btn-text="$t('registrationLinkModal.sendTokenEmailBtn')"
      :fields="fields"
      :submit-promise-callback="submit"
      @close="closeModal"
  >
    <template #field.role="{ errors, label, classes }">
      <v-select
          :label="label"
          outlined
          :class="classes"
          :error-messages="errors"
          v-model="obj.role"
          :items="roleTypes"
      >
        <template #item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-text="item.text"/>
            <v-list-item-subtitle v-text="item.description"/>
          </v-list-item-content>
        </template>
      </v-select>
    </template>
  </action-modal>
</template>

<script>

import {ActionModal} from '@simple-it/shared-vuetify'

export default {
  name: 'InviteUserToPlatformModal',
  components: { ActionModal },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    specificRole: String,
    defaultRole: String
  },
  data () {
    return {
      obj: {
        email: null,
        role: this.defaultRole ?? null
      },
      fields: this.specificRole ? [ {
        label: 'Email',
        value: 'email',
        rules: 'required|email',
        cols: '12'
      } ]
          // else
          : [ {
        label: 'Email',
        value: 'email',
        rules: 'required|email',
        cols: '12'
      }, {
        label: 'Ruolo',
        value: 'role',
        rules: 'required',
        cols: '12'
      } ],
      roleTypes: [
        {
          text: 'Amministratore',
          value: 'foodytek-client-admin',
          description: "Ha accesso completo all'organizzazione, inclusa interfaccia Dashboard e " +
              "applicazioni (Corporation e Business)."
        },
        {
          text: 'Installatore',
          value: 'foodytek-client-installer',
          description: "Ha accesso solo alle applicazioni" +
              " (Corporation e Business)."
        },
        {
          text: 'Commercialista',
          value: 'foodytek-client-accountant',
          description: "Ha accesso solo alle funzioni di reportistica disponibili sull'interfaccia " +
              "Dashboard (report transazioni mensili, fatture e altri report)."
        },
      ]
    }
  },
  watch: {
    defaultRole (newValue) {
      if (newValue) {
        this.obj.role = newValue
      }
    }
  },
  computed: {
    modalVisible: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    submit () {
      const role = this.specificRole ?? this.obj.role
      return this.$http.auth.post(
          'invitations/invite/' + role,
          { email: this.obj.email }
      ).then((response) => {
        const token = response.data
        this.showDialogToCopyLink(token)
        this.closeModal()
      })
    },
    async showDialogToCopyLink (token) {
      const baseUrl = this.$env.isLocalhost
          ? 'http://localhost:8080'
          : 'https://login' + (this.$env.isDev ? '.dev' : '') + '.foodytek.com'
      const url = baseUrl + '/register/' + token

      const that = this
      await this.$dialog.confirm({
        title: 'Link di registrazione',
        text: `È stata inviata una email con il link di invito. Se vuoi puoi anche copiare il link e inviarlo
                manualmente alla persona indicata.<br><br>`,
        icon: false,
        actions: [
          {
            text: 'Annulla'
          },
          {
            text: 'Copia link',
            icon: 'fa-copy',
            color: 'primary',
            outlined: true,
            handler () {
              that.copyTextToClipboard(url)
              that.$dialog.notify.success('Link copiato negli appunti. Ora puoi incollarlo nella mail.')
            }
          }
        ]
      })
    },
    closeModal () {
      this.createModal = false
      this.$emit('close')
    }
  }
}
</script>
