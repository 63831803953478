<script>
import InviteUserToPlatformModal from "@/components/modals/commons/InviteUserToPlatformModal.vue";

export default {
  name: 'OrganisationUsers',
  components: { InviteUserToPlatformModal },
  data () {
    return {
      users: [],
      tokens: [],
      search: '',
      inviteModal: {
        show: false,
        defaultRole: null
      },

      tokenHeaders: [
        { text: 'Email', value: 'email' },
        { text: 'Ruolo', value: 'role' },
        { value: 'crud', text: '', align: 'center', sortable: false }
      ]
    }
  },
  computed: {
    headers () {
      return [
        { text: this.$t('commons.data.name'), value: 'name' },
        { text: this.$t('commons.data.surname'), value: 'surname' },
        { text: 'Email', value: 'email' },
        { text: 'Ruolo', value: 'role' },
        { value: 'crud', text: 'Azioni', align: 'center', sortable: false }
      ]
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getRegistrationTokens()
      this.getUsers()

      if (this.$route.query.invite) {
        this.openInviteModal(this.$route.query.invite)
        this.$router.replace({ query: {} })
      }
    },
    reload () {
      setTimeout(this.init, 200)
    },
    openInviteModal (roleQuery) {
      if (roleQuery) {
        this.inviteModal.defaultRole = 'foodytek-client-' + roleQuery
      }
      this.inviteModal.show = true
    },
    getRoleFromCode (roleCode) {
      switch (roleCode) {
        case 'foodytek-client-admin':
          return { label: 'Amministratore', color: 'warning darken-2' }
        case 'foodytek-client-installer':
          return { label: 'Installatore', color: 'grey darken-1' }
        case 'foodytek-client-accountant':
          return { label: 'Commercialista', color: 'primary' }
        default:
          return { label: '-', color: 'danger darken-2' }
      }
    },
    async getUsers () {
      try {
        const response = await this.$http.auth.get('/users/ft-client/' + this.$store.getters.clientId)
        response.data.forEach(i => {
          i.role = this.getRoleFromCode(i.roleCode)
        })
        this.users = response.data
      } catch (e) {
      }
    },
    async getRegistrationTokens () {
      try {
        const response = await this.$http.auth.get('invitations/ft-client')
        response.data.forEach(i => {
          i.role = this.getRoleFromCode(i.roleCode)
        })
        this.tokens = response.data
      } catch (e) {
      }
    },

    async removeUser (item) {
      try {
        await this.$dialog.confirmation.deletion(this.$t('user.downgradeMessage', { email: item.email }), this.$t('user.downgrade'))
        await this.$http.auth.delete(`users/${item.id}/downgrade`)
        this.reload()
      } catch (e) {
      }
    },
    async revokeToken (item) {
      try {
        await this.$dialog.confirmation.deletion(this.$t('user.revokeTokenMessage', { email: item.email }), this.$t('user.revoke'))
        await this.$http.auth.delete('invitations/' + item.id)
        this.reload()
      } catch (e) {
      }
    }
  }
}
</script>

<template>
  <div>
    <h1>Utenti organizzazione</h1>
    <v-card class="my-6" outlined v-if="tokens && tokens.length">
      <v-card-title>{{ $t('usersManagement.activeRegistrationTokens') }}</v-card-title>
      <v-card-text>
        <v-data-table
            :items="tokens"
            :headers="tokenHeaders"
            item-key="item.id"
            :no-data-text="$t('usersManagement.noData')"
        >
          <template v-slot:item.role="{ value }">
            <v-chip outlined :color="value.color" small>{{ value.label }}</v-chip>
          </template>

          <template v-slot:item.crud="{ item }">
            <v-btn color="red"
                   class="pa-5"
                   small icon
                   @click="revokeToken(item)">
              <v-icon small>fa-trash</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-card class="my-6" outlined>
      <v-card-title>Utenti che hanno accesso alla dashboard</v-card-title>
      <v-card-text>
        <div class="d-flex align-center mx-5">
          <v-text-field
              v-model="search"
              append-icon="fa-search"
              outlined
              :label="$t('commons.search')"
              single-line
              hide-details/>
          <v-btn
              class="ml-6"
              color="green" dark
              v-text="'Invita'"
              @click="openInviteModal()"/>
        </div>
      </v-card-text>
        <v-data-table
            :items="users"
            :headers="headers"
            :search="search"
            item-key="item.id"
            :no-data-text="$t('usersManagement.noData')"
        >
          <template v-slot:item.role="{ value }">
            <v-chip outlined :color="value.color" small>{{ value.label }}</v-chip>
          </template>

          <template v-slot:item.crud="{ item }">
            <v-btn color="red"
                   class="pa-5"
                   small icon
                   @click="removeUser(item)">
              <v-icon small>fa-angle-double-down</v-icon>
            </v-btn>
          </template>
        </v-data-table>
    </v-card>

    <invite-user-to-platform-modal
        v-model="inviteModal.show"
        :default-role="inviteModal.defaultRole"
        @close="reload"
    />
  </div>
</template>
