<template>
  <div v-if="ftClient">
    <tab-layout title="Admin settings" v-if="$store.getters.isAdmin">
      <v-btn color="primary" outlined @click="loginAsClient">Accedi alla dashboard del cliente</v-btn>

      <platform-fee-form class="mt-6" :ft-client-id="ftClientId" />
    </tab-layout>
    <tab-layout title="Sticker scaricabili">
      <base-q-r-code-sticker-modal :qr-content="'https://foodytek.com/create-vk?ftClient=' + this.ftClientId" />
    </tab-layout>
    <tab-layout title="Informazioni generali" enable-edit-mode @reload="reload" @save="saveDetails">
      <v-row>
        <ft-col-info-view v-if="$store.getters.isAdmin" label="ID" v-model="ftClientId" not-editable/>
        <ft-col-info-view label="Ragione sociale" v-model="ftClient.businessName"/>
        <ft-col-info-view label="Nome da visualizzare" v-model="ftClient.displayName"/>
        <ft-col-info-view label="Partita IVA" v-model="ftClient.vatId" @input="checkVatId"/>
        <ft-col-info-view label="Codice fiscale" v-model="ftClient.taxId"/>
        <ft-col-info-view v-if="ftClient.country === 'it'" label="Codice destinatario" v-model="ftClient.codiceDestinatario" rules="required|length:7"/>
        <ft-col-info-view label="Paese" :value="getCountryName(ftClient.country)" not-editable/>
      </v-row>
    </tab-layout>

    <tab-layout title="Sede fiscale" enable-edit-mode @reload="reload" @save="saveAddress">
      <v-row>
        <ft-col-info-view label="Indirizzo linea 1" v-model="address.line1"/>
        <ft-col-info-view label="linea 2" v-model="address.line2" rules=""/>
        <ft-col-info-view label="Città" v-model="address.city"/>
        <ft-col-info-view label="Provincia" :value="getStateName(address.state) + ' (' + address.state + ')'">
          <template v-slot:edit-field="{ errors }" v-if="ftClient.country === 'it'">
            <v-autocomplete
                v-model="address.state"
                :label="$t('commons.data.state')"
                :items="italianProvinces"
                outlined
                dense
                hide-details
                :error="errors.length > 0"
            />
          </template>
        </ft-col-info-view>
        <ft-col-info-view label="CAP" v-model="address.postalCode"/>
      </v-row>
    </tab-layout>

    <tab-layout title="Legale rappresentante" enable-edit-mode @reload="reload" @save="saveLegalRepresentative">
      <v-row>
        <ft-col-info-view label="Nome" v-model="legalRepresentative.name"/>
        <ft-col-info-view label="Cognome" v-model="legalRepresentative.lastName"/>
        <ft-col-info-view label="Data di nascita" :value="formatDate(legalRepresentative.birthDate)">
          <template v-slot:edit-field="{ errors }">
            <ft-date-field
                v-model="legalRepresentative.birthDate"
                outlined
                dense
                hide-details
                :error="errors.length > 0"
            />
          </template>
        </ft-col-info-view>
        <ft-col-info-view label="Luogo di nascita" v-model="legalRepresentative.birthplace"/>
      </v-row>
    </tab-layout>
  </div>
</template>

<script>
import TabLayout from "@/components/UIAccessories/TabLayout.vue";
import FtColInfoView from "@/components/UIAccessories/FtColInfoView.vue";
import Countries_provinces from "@/mixins/countries_provinces";
import FtDateField from "@/components/fields/FtDateField.vue";
import BaseQRCodeStickerModal from "@/components/modals/commons/BaseQRCodeStickerModal.vue";
import PlatformFeeForm from "@/components/PlatformFeeForm.vue";

export default {
  name: 'ClientInformationCmp',
  components: { PlatformFeeForm, BaseQRCodeStickerModal, FtDateField, FtColInfoView, TabLayout },
  mixins: [Countries_provinces],
  props: {
    ftClientId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      ftClient: null,
      address: null,
      legalRepresentative: null,
    }
  },
  mounted () {
    this.loadInfo()
  },
  computed: {
    birthDateComputed: {
      get () {
        return new Date(this.legalRepresentative.birthDate).toISOString().split('T')[0]
      },
      set (newValue) {
        this.legalRepresentative.birthDate = newValue.replace('-', '/')
      }
    }
  },
  methods: {
    checkVatId () {
      if (this.ftClient.country === 'it') {
        if (!this.ftClient.vatId.startsWith('IT')) {
          this.ftClient.vatId = 'IT' + this.ftClient.vatId
        }
      }
    },
    reload () {
      setTimeout(this.loadInfo, 800)
    },
    async loadInfo () {
      const res = await this.$http.auth.get('ft-clients/' + this.ftClientId)
      const c = res.data
      // the following check is only needed in the transition period after the introduction of the legal representative
      // (the field is null for old clients)
      if (!c.legalRepresentative) this.legalRepresentative = {}
      else {
        this.legalRepresentative = c.legalRepresentative
      }

      this.address = c.address
      delete c.address
      delete c.legalRepresentative
      this.ftClient = c
    },
    async saveDetails () {
      this.checkVatId()
      const details = Object.assign({}, this.ftClient)
      delete details.id
      await this.$http.auth.put('ft-clients/' + this.ftClientId, details)
    },
    async saveAddress () {
      await this.$http.auth.put(`ft-clients/${this.ftClientId}/address`, this.address)
    },
    async saveLegalRepresentative () {
      await this.$http.auth.put(`ft-clients/${this.ftClientId}/legal-representative`, this.legalRepresentative)
    },
    loginAsClient () {
      this.$store.dispatch('loginAsClient', this.ftClientId)
    }
  }
}
</script>

<style scoped>

</style>
