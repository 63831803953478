<template>
  <action-modal
      v-if="show"
      v-model="show"
      :data="obj"
      cancel-btn-text="Annulla"
      loading-text="Updating balance..."
      :submit-title="`Correggi ricarica per ${user.email}`"
      submit-success-message="Credito rimosso con successo!"
      submit-btn-text="Rimuovi credito"
      :submit-promise-callback="revertTopUp"
      :fields="[{
          label: 'Importo',
          value: 'amount',
          rules: 'required|min:1',
          cols: '12',
          sm: '6'
      },
      {
          label: 'Canale',
          value: 'type',
          rules: 'required|min:1',
          cols: '12',
          sm: '6'
      }]"
  >
    <template #field.amount="{ errors, label, classes }">
      <price-input
          :label="label"
          outlined
          :class="classes"
          :error-messages="errors"
          v-model="obj.amount"
      />
    </template>
    <template #field.type="{ errors, label, classes }">
      <v-select
          :label="label"
          outlined
          :class="classes"
          :error-messages="errors"
          v-model="obj.type"
          :items="revertTypes"
      />
    </template>
  </action-modal>
</template>

<script>
import { ActionModal } from '@simple-it/shared-vuetify'
import PriceInput from "@/components/fields/PriceInput.vue";

export default {
  name: 'RevertTopUpVirtualKeyModal',
  components: { PriceInput, ActionModal },
  props: {
    ftClientId: String,
    user: Object
  },
  data () {
    return {
      revertTypes: [
        { text: 'Cash', value: 'CASH' },
        { text: 'Carta', value: 'CARD' },
        { text: 'Regalo', value: 'GIFT' },
      ],
      obj: {
        amount: null,
        type: null,
      }
    }
  },
  computed: {
    show: {
      get () {
        return this.user != null
      },
      set () {
        this.$emit('update:user', null)
        this.$emit('close')
      }
    }
  },
  watch: {
    ftClientId (newVal) {
      if (newVal) this.reloadDevices()
    }
  },
  methods: {
    reloadDevices () {
      this.obj.amount = null
      this.obj.serialNumber = null
      this.getDevices()
    },
    revertTopUp () {
      return this.$http.payment.post(`virtual-keys/admin/${this.ftClientId}/${this.user.id}/top-up/dashboard/revert`, {
        serialNumber: this.obj.serialNumber,
        amount: this.obj.amount,
        type: this.obj.type,
      })
    }
  }
}
</script>
