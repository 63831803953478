<template>
  <v-container>
    <h1 class="text-h4">{{ $t('deviceUpdates.title') }}</h1>

    <div class="mx-auto my-6">
      <span class="">Latest version:</span>
      <span class="ml-3 font-weight-bold">{{ lastVersion }}</span>

      <v-btn class="ml-16" color="primary" @click="openUploadModal">Upload new version</v-btn>
    </div>

    <h4 class="mt-6 text-h6 text-left">All versions</h4>
    <v-list>
      <v-list-item v-for="v in versions" v-bind:key="v">{{ v }}</v-list-item>
    </v-list>

    <upload-firmware-modal
        v-model="modal"
        @close="getData"
    />
  </v-container>
</template>

<script>
import UploadFirmwareModal from '@/components/modals/administration/UploadFirmwareModal'

export default {
  name: 'DeviceUpdates',
  components: { UploadFirmwareModal },
  data () {
    return {
      modal: false,
      lastVersion: null,
      versions: null
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      try {
        const lastVersionResponse = await this.$http.device('firmwares/foodytek-pk/last-version')
        this.lastVersion = lastVersionResponse.data

        const versionsResponse = await this.$http.device('firmwares/foodytek-pk')
        this.versions = versionsResponse.data
      } catch (e) {
      }
    },
    async refresh () {
      await this.getData()
      await this.$dialog.notify.success('Reloaded successfully')
    },
    openUploadModal () {
      this.modal = true
    }
  }
}
</script>

<style scoped>

</style>
