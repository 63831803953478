var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.users)?_c('v-card',{attrs:{"outlined":""}},[_c('div',{staticClass:"ma-5 text-center",staticStyle:{"font-size":"1.2rem"}},[_vm._v(" Totale saldi utenti: "),_c('b',[_vm._v(_vm._s(_vm.formatMoney(_vm.totalBalances)))])]),(_vm.ftClientId && _vm.users)?_c('v-data-table',{staticClass:"text-center",attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.query.q,"items-per-page":_vm.query.itemsPerPage,"page":_vm.query.page,"item-key":"userId","sort-by":"name"},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.query, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.query, "itemsPerPage", $event)},"update:page":function($event){return _vm.$set(_vm.query, "page", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-center px-8"},[_c('v-text-field',{staticClass:"mr-6 mt-6",attrs:{"label":"Filtra utenti nella tabella (nome, email, id...)"},model:{value:(_vm.query.q),callback:function ($$v) {_vm.$set(_vm.query, "q", $$v)},expression:"query.q"}}),_c('email-create-v-k-modal',{attrs:{"ft-client-id":_vm.ftClientId},on:{"close":_vm.reload}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-chip',{attrs:{"outlined":""},on:{"click":function($event){return _vm.openTransactions(item)}}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.balance))+" ")]}},{key:"item.lastTimeUsed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateNoSecs(item.lastTimeUsed))+" ")]}},{key:"item.groups",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('div',_vm._l((value),function(group){return _c('v-chip',{key:group.id,staticClass:"mr-2",attrs:{"outlined":"","small":"","to":("/groups-management/" + (group.id)),"disabled":group.loading},on:{"contextmenu":function($event){$event.preventDefault();return _vm.showContextMenu($event, group, item.user)}}},[_vm._v(" "+_vm._s(group.name)+" ")])}),1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.topUpVK(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ricarica utente")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){_vm.userReverseTopUpModal = item.user}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-minus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Correggi ricarica (rimuovi credito)")])]),(_vm.$store.getters.isAdmin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){_vm.userChargeModal = item}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-cart-shopping ")])],1)]}}],null,true)},[_c('span',[_vm._v("Create purchase (admin only)")])]):_vm._e(),(_vm.$store.getters.isClient)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":function($event){_vm.addUserToGroupModal = item}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-user-group ")])],1)]}}],null,true)},[_c('span',[_vm._v("Aggiungi utente ad un gruppo")])]):_vm._e()],1)]}}],null,false,965950145)}):_vm._e(),_c('v-menu',{attrs:{"position-x":_vm.groupContextMenu.x,"position-y":_vm.groupContextMenu.y,"absolute":""},model:{value:(_vm.groupContextMenu.visible),callback:function ($$v) {_vm.$set(_vm.groupContextMenu, "visible", $$v)},expression:"groupContextMenu.visible"}},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.removeUserFromGroup}},[_c('v-list-item-title',[_vm._v("Rimuovi utente da questo gruppo")])],1)],1)],1),_c('top-up-virtual-key-modal',{attrs:{"ft-client-id":_vm.ftClientId,"user":_vm.userTopUpModal},on:{"update:user":function($event){_vm.userTopUpModal=$event},"close":_vm.reload}}),(_vm.$store.getters.isAdmin)?_c('charge-virtual-key-modal',{attrs:{"ft-client-id":_vm.ftClientId,"user":_vm.userChargeModal},on:{"update:user":function($event){_vm.userChargeModal=$event},"close":_vm.reload}}):_vm._e(),_c('revert-top-up-virtual-key-modal',{attrs:{"ft-client-id":_vm.ftClientId,"user":_vm.userReverseTopUpModal},on:{"update:user":function($event){_vm.userReverseTopUpModal=$event},"close":_vm.reload}}),(_vm.$store.getters.isClient)?_c('add-user-to-group-modal',{attrs:{"user":_vm.addUserToGroupModal,"ft-client-id":_vm.ftClientId},on:{"update:user":function($event){_vm.addUserToGroupModal=$event},"close":_vm.reload}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }