<template>
  <f-nav-bar
      :current-lang="$i18n.locale"
      :is-authenticated="$store.getters.isAuthenticated"
      registration-complete
      :operations="operations"
      :user-info="userInfo"
      @changeLang="changeLang"
      @returnToAdminDashboard="returnToAdminDashboard"
      @logoutUser="logoutUser"
  />
</template>

<script>

import FNavBar from '@/components/NavBar/FNavBar/FNavBar'

export default {
  name: 'NavBar',
  components: { FNavBar },
  computed: {
    operations () {
      const { isAdmin, isClient, isClientCustomer, isClientAccountant, featureFlags, ccRegComplete } = this.$store.getters

      const adminOperations = [
        () => 'Clients e amministratori',
        { name: () => { return this.$t('administrators.title') }, icon: 'fa-users-cog', path: '/administrators' },
        { name: () => { return this.$t('foodytekClients.title') }, icon: 'fa-users', path: '/ft-clients' },

        undefined,
        () => 'Gestione dispositivi',

        { name: () => { return 'Devices Management' }, icon: 'fa-microchip', path: '/devices' },
        // { name: () => { return this.$t('unassignedDevices.title') }, icon: 'fa-warehouse', path: '/devices/unassigned' },
        { name: () => { return this.$t('deviceUpdates.title') }, icon: 'fa-cloud-upload-alt', path: '/device-updates' },

        undefined,
        () => 'Utenti',

        { name: () => { return 'Users wallet' }, icon: 'fa-wallet', path: '/users-wallet' },

        undefined,
        () => 'Utilities',

        { name: () => { return 'QR generator' }, icon: 'fa-qrcode', path: '/qr-code-generator' },

      ].filter(op => !op || op.hide !== true)

      // ----------------------------------------------------------------

      const clientAccountantOperations = [
        () => 'Contabilità',
        { name: () => { return 'Report mensili' }, path: '/monthly-reports', icon: 'fa-file-invoice-dollar' },
      ]

      const clientOperations = [
        { name: () => { return 'Test local page' }, path: '/test-local-page', icon: 'fa-tools', hide: !this.$env.isLocalhost },

        () => 'Statistiche',
        { name: () => { return 'Dashboard' }, path: '/dashboard', icon: 'fa-chart-line' },
        { name: () => { return 'Statistiche per utente' }, path: '/dashboard/per-user', icon: 'fa-chart-column' },

        undefined,
        () => 'Account',
        { name: () => { return this.$t('myAccount.title') }, path: '/my-account', icon: 'fa-user-cog' },
        { name: () => { return 'Utenti org.' }, path: '/client-users', icon: 'fa-user-tie', newBadge: true },
        { name: () => { return 'Aziende clienti' }, path: '/users/client-customers', icon: 'fa-building' },

        undefined,
        () => 'Contabilità',
        { name: () => { return 'Report mensili' }, path: '/monthly-reports', icon: 'fa-file-invoice-dollar', newBadge: true },
        { name: () => { return 'Corrispettivi AE' }, path: '/corrispettivi', icon: 'fa-sack-dollar' },
        { name: () => { return 'Ricariche remote' }, path: '/remote-top-ups', icon: 'fa-house-laptop' },
        { name: () => { return 'Ricariche e regali' }, path: '/top-up-and-gift', icon: 'fa-gift' },

        undefined,
        () => 'Dispositivi',

        { name: () => { return 'Tutti i dispositivi' }, path: '/devices', icon: 'fa-microchip' },
        { name: () => { return 'Partners, sedi e dispositivi' }, path: '/partners', icon: 'fa-industry' },
        { name: () => { return 'Dispositivi non attivi' }, path: '/devices/not-active', icon: 'fa-store-slash' },
        { name: () => { return this.$t('devicesTransactions.title') }, path: '/devices/transactions', icon: 'fa-exchange-alt' },

        undefined,
        () => 'Utenti',

        { name: () => { return this.$t('usersVirtualKeys.title') }, path: '/virtual-keys', icon: 'fa-wallet' },
        { name: () => { return 'Gestione gruppi' }, path: '/groups-management', icon: 'fa-user-group' },

      ].filter(op => !op || op.hide !== true)

      // ----------------------------------------------------------------

      const clientCustomerOperations = [
        () => 'Il tuo account',
        { name: () => { return 'Informazioni account' }, path: '/client-customers/info', icon: 'fa-user-cog', disabled: !ccRegComplete },
        { name: () => { return 'Ricarica remota Wallet' }, path: '/client-customers/remote-top-up', icon: 'fa-wallet', disabled: !ccRegComplete },
        { name: () => { return 'Ricariche passate' }, path: '/client-customers/past-top-ups', icon: 'fa-clock-rotate-left', disabled: !ccRegComplete },
        // { name: () => { return 'Fatture' }, path: '/client-customers/past-top-ups', icon: 'fa-file-invoice' },
      ].filter(op => !op || op.hide !== true)

      // ----------------------------------------------------------------

      if (isAdmin) return adminOperations
      else if (isClient) return clientOperations
      else if (isClientAccountant) return clientAccountantOperations
      else if (isClientCustomer) return clientCustomerOperations
      else return []

    },
    userInfo () {
      return {
        name: this.$store.getters.name,
        surname: this.$store.getters.surname,
        email: this.$store.getters.email
      }
    }
  },
  methods: {
    changeLang (lang) {
      this.$i18n.locale = lang
      this.$store.commit('changeLang', lang)
    },
    logoutUser () {
      this.$store.commit('logoutUser')
    },
    returnToAdminDashboard () {
      this.$store.dispatch('returnToAdminDashboard')
    }
  }
}
</script>
