function fallbackCopyTextToClipboard (text) {
  var textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
    // var successful = document.execCommand('copy')
    // var msg = successful ? 'successful' : 'unsuccessful'
    // console.log('Fallback: Copying text command was ' + msg)
  } catch (err) {
    // console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}

function formatDateObject (dateObj) {
  const offset = dateObj.getTimezoneOffset()
  dateObj = new Date(dateObj.getTime() - (offset * 60 * 1000))
  return dateObj.toISOString().replace('T', ', ').replace('Z', '')
}

export default {
  methods: {
    formatDuration (durationMicroseconds) {
      const ms = Math.floor(durationMicroseconds / 1000)
      const s = Math.floor(ms / 1000)
      const m = Math.floor(s / 60)
      const h = Math.floor(m / 60)
      const d = Math.floor(h / 24)

      const sStr = s ? s % 60 + 's ' : ''
      const mStr = m ? m % 60 + 'm ' : ''
      const hStr = h ? h % 24 + 'h ' : ''
      const dStr = d ? d + 'd ' : ''

      return `${dStr}${hStr}${mStr}${sStr}${ms % 1000}ms`

      // return `${d}d ${h % 24}h ${m % 60}m ${s % 60}s ${ms % 1000}ms`
      // return `${d}d-${h % 24}h-${m % 60}m-${s % 60}s-${ms % 1000}ms`
    },
    formatDate (date) {
      if (!date) return '-'

      if (date instanceof Array && date.length === 3) {
        return date.reverse().join('/')
      }

      return new Date(date).toLocaleDateString(this.$store.getters.language)
    },
    formatDateTime (dateNumber) {
      return dateNumber
        ? formatDateObject(new Date(dateNumber * 1000))
      // ? new Date(dateNumber * 1000).toLocaleString(this.$store.getters.language)
        : this.$t('devicesTransactions.noDateText')
    },
    formatDateNoSecs (dateNumber) {
      return dateNumber
        ? formatDateObject(new Date(dateNumber * 1000)).substring(0, 17)
      // ? new Date(dateNumber * 1000).toLocaleString(this.$store.getters.language)
        : this.$t('devicesTransactions.noDateText')
    },
    formatDateYearFirst (dateNumber) {
      return new Date(dateNumber).toISOString().slice(0, 10)
    },
    formatMoney (value) {
      return (value / 100).toFixed(2) + ' €'
    },

    copyTextToClipboard (text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text)
      // .then(function () {
      //   console.log('Async: Copying to clipboard was successful!')
      // }, function (err) {
      //   console.error('Async: Could not copy text: ', err)
      // })
    },

    createFileAndDownload (data, type, filename) {
      const blob = new Blob([data], { type })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url

      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    }
  }
}
