var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',[_vm._v("Virtual Key transactions")]),_c('div',{staticClass:"mt-2 mb-4"},[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.userDetails.name)+" "+_vm._s(_vm.userDetails.surname)+" - "+_vm._s(_vm.userDetails.email)+" ")]),_c('v-chip',{staticClass:"my-4",attrs:{"outlined":"","color":"primary","large":""}},[_vm._v(" Credito: "),_c('b',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.formatMoney(_vm.vk.balance)))]),_vm._v(" "),_c('small',{staticClass:"ml-3"},[_vm._v("("+_vm._s(_vm.formatDateNoSecs(_vm.vk.lastTimeUsed))+")")])]),(_vm.$store.getters.isAdmin || _vm.$store.getters.isAdminLoggedAsClient)?_c('div',{staticClass:"my-2"},[_vm._v(" User id: "+_vm._s(_vm.userId)+" ")]):_vm._e(),_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Azioni:")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.topUpVK()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-plus ")])],1)]}}])},[_c('span',[_vm._v("Ricarica utente")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":"","icon":"","color":"primary"},on:{"click":function($event){_vm.userReverseTopUpModal = _vm.userDetails}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-minus ")])],1)]}}])},[_c('span',[_vm._v("Correggi ricarica (rimuovi credito)")])]),(_vm.$store.getters.isAdmin)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":"","icon":"","color":"primary"},on:{"click":function($event){_vm.userChargeModal = _vm.userDetails}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" fa-cart-shopping ")])],1)]}}],null,false,2715396181)},[_c('span',[_vm._v("Create purchase")])]):_vm._e()],1)],1),(_vm.testCardTopUp.show)?_c('v-card',{staticClass:"mb-6 mt-2",staticStyle:{"max-width":"40rem"},attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" Test Card Top Up ")]),_c('v-card-text',[_c('div',{staticClass:"d-flex align-center"},[_c('v-text-field',{staticStyle:{"max-width":"10rem"},attrs:{"label":"Importo in cents","type":"number"},model:{value:(_vm.testCardTopUp.amount),callback:function ($$v) {_vm.$set(_vm.testCardTopUp, "amount", $$v)},expression:"testCardTopUp.amount"}}),_c('v-btn',{staticClass:"mx-5",attrs:{"color":"primary"},on:{"click":_vm.performCardTopUp}},[_vm._v("Ricarica")])],1),(_vm.testCardTopUp.showPaymentElement)?_c('div',[_c('div',{attrs:{"id":"payment-element"}}),_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","outlined":""},on:{"click":_vm.completeStripePayment}},[_vm._v("Paga")])],1):_vm._e()])],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 text-center mt-3",attrs:{"headers":_vm.headers,"items":_vm.transactions,"item-key":"userId","sort-by":"name"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._v(" "+_vm._s(_vm.getLast4Digits(value))+" "),_c('v-chip',_vm._g({attrs:{"small":"","link":"","outlined":""},on:{"click":function($event){return _vm.copyTextToClipboard(value)}}},on),[_vm._v("Copy ID")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(value)+" ")])]):_vm._e()]}},{key:"item.serialNumber",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value)?_c('v-chip',{attrs:{"link":"","outlined":"","to":("/devices/" + value)}},[_vm._v(_vm._s(value))]):_vm._e(),(value && _vm.$store.getters.isAdmin)?_c('span',[_vm._v(_vm._s(item.macAddress))]):_vm._e()]}},{key:"item.amount",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoneyWithSign(value, item))+" "),(item.purchaseAmount && value !== item.purchaseAmount.full)?_c('span',{staticClass:"text-caption ml-1"},[_vm._v(" ("+_vm._s(_vm.formatMoney(item.purchaseAmount.full))+") ")]):_vm._e()]}},{key:"item.datetime",fn:function(ref){
var value = ref.value;
return [(_vm.$store.getters.isAdmin)?_c('span',[_vm._v(_vm._s(_vm.formatDateTime(value)))]):_c('span',[_vm._v(_vm._s(_vm.formatDateNoSecs(value)))])]}},{key:"item.type",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.getTransTypeMsgByCode(value))+" ")]}},{key:"item.refunded",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" Sì "),(_vm.$store.getters.isAdmin)?_c('span',[_vm._v(": "+_vm._s(_vm.getReasonCode(item.refundReason))+" ("+_vm._s(item.refundReason)+")")]):_vm._e()])]}}],null,true)},[_c('span',[_vm._v(" Rimborsato il:"),_c('br'),_c('b',[_vm._v(_vm._s(_vm.formatDateTime(item.refundDatetime)))])])])],1):(item.refundedTransactionId)?_c('div',[(!_vm.$store.getters.isAdmin)?_c('span',[_vm._v("Rimborso")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._v(" "+_vm._s(_vm.getLast4Digits(item.refundedTransactionId))+" "),_c('v-chip',_vm._g({attrs:{"small":"","link":"","outlined":""},on:{"click":function($event){return _vm.copyTextToClipboard(item.refundedTransactionId)}}},on),[_vm._v("Copy refunded ID")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(value)+" ")])])],1):_c('div',[_vm._v("-")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.type === 'PURCHASE_VK')?_c('v-btn',{attrs:{"disabled":item.refunded || item.disabled,"small":"","text":"","color":"primary"},on:{"click":function($event){return _vm.refundTransaction(item)}}},[_vm._v(" Rimborsa ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" Reset ")])]},proxy:true}])}),_c('top-up-virtual-key-modal',{attrs:{"ft-client-id":_vm.ftClientId,"user":_vm.userTopUpModal},on:{"update:user":function($event){_vm.userTopUpModal=$event},"close":_vm.reloadDelayed}}),_c('revert-top-up-virtual-key-modal',{attrs:{"ft-client-id":_vm.ftClientId,"user":_vm.userReverseTopUpModal},on:{"update:user":function($event){_vm.userReverseTopUpModal=$event},"close":_vm.reloadDelayed}}),(_vm.$store.getters.isAdmin)?_c('charge-virtual-key-modal',{attrs:{"ft-client-id":_vm.ftClientId,"user":_vm.userChargeModal},on:{"update:user":function($event){_vm.userChargeModal=$event},"close":_vm.reloadDelayed}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }