import i18n from '@/plugins/i18n'
import Vue from "vue";
import vuetify from "@/plugins/vuetify";

const state = {
  language: null,
  featureFlags: {}
}

const getters = {
  language: (state) => {
    return state.language
  },
  featureFlags: (state) => {
    return state.featureFlags
  }
}

const mutations = {
  changeLang (state, lang) {
    state.language = lang
    i18n.locale = lang
    // vuetify.framework.lang.current = lang
    document.title = i18n.t('commons.appTitle').toString()
  },
  setFeatureFlags (state, featureFlags) {
    state.featureFlags = featureFlags
  }
}

const actions = {
  async updateAppSettings ({ commit, dispatch }) {
    await dispatch('updateRegComplete')

    // Update feature flags
    try {
      const res = await Vue.prototype.$http.device.get('feature-flags')
      if (typeof res.data === 'object') commit('setFeatureFlags', res.data)
    } catch (e) {
      console.error('Error fetching feature flags')
    }
  },
  async loadPage ({ commit, dispatch, getters }) {
    try {
      if (getters.isAuthenticated) {
        await dispatch('updateAppSettings')
        commit('changeLang', this.$store.getters.language)
      }
    } catch (e) {
    }
  }
}

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
