<template>
  <div>
    <h1>{{ $t('partners.title') }}</h1>
    <div class="d-flex align-center ma-5">
      <v-text-field
          v-model="search"
          append-icon="fa-search"
          :label="$t('commons.search')"
          single-line
          outlined
          hide-details/>
      <v-btn
          class="ml-6"
          color="green"
          dark
          v-text="$t('commons.create')"
          @click="createModal"/>
    </div>

    <v-card outlined>
      <v-data-table
          class="row-pointer"
          :loading="loading"
          :items="partners"
          :headers="headers"
          :search="search"
          item-key="item.id"
          @page-count="pageCount = $event"
          :no-data-text="$t('partners.noData')"
          @click:row="handleRowClick"
      >
        <template v-slot:item.address="{ item }">
          {{ item.address ? item.address + " " + item.civicNumber : '' }}
        </template>
        <template v-slot:item.crud="{ item }">
          <v-btn
              color="warning"
              class="pa-5"
              small
              icon
              @click.stop="editModal(item)">
            <v-icon small>fa-edit</v-icon>
          </v-btn>
          <v-btn color="red"
                 class="pa-5"
                 small icon
                 @click.stop="openDeleteLocationModal(item)">
            <v-icon small>fa-trash</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <partner-modal
        v-model="actionModal"
        :edit-mode="actionModalEditMode"
        :partner="modalObject"
        @close="closeActionModal"/>
  </div>
</template>

<script>
import PartnerModal from '@/components/modals/business/PartnerModal.vue'

export default {
  name: 'PartnersList',
  components: { PartnerModal },
  data () {
    return {
      loading: false,
      partners: [],
      search: '',

      confirmModal: false,
      actionModal: false,
      actionModalEditMode: false,
      modalObject: {
        id: null,
        name: null,
        address: null,
        city: null,
        civicNumber: null
      }
    }
  },
  computed: {
    headers () {
      return [
        { text: this.$t('commons.data.name'), value: 'name' },
        { text: this.$t('commons.data.businessName'), value: 'businessName' },
        { text: this.$t('commons.data.city'), value: 'city' },
        { text: this.$t('commons.data.address'), value: 'address' },
        { text: '', value: 'crud' }
      ]
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    reload() {
      setTimeout(this.init, 600)
    },
    async openDeleteLocationModal (location) {
      try {
        await this.$dialog.confirmation.deletion(this.$t('partners.messages.confirmDeletion').toString() + ' <b>"' + location.name + '"</b>')
        await this.deleteLocation(location.id)
      } catch (e) {
      }
    },
    async deleteLocation (id) {
      try {
        await this.$http.device.delete('/partners/' + id)
        this.confirmModal = false
        this.$dialog.notify.success(this.$t('partners.messages.deleted').toString())
        this.reload()
      } catch (e) {
      }
    },
    async init () {
      try {
        this.loading = true
        const result = await this.$http.device.get('/partners')
        this.loading = false
        this.partners = result.data
      } catch (e) {
        this.loading = false
      }
    },
    createModal () {
      this.actionModalEditMode = false
      this.modalObject = {}
      this.actionModal = true
    },
    editModal (partner) {
      this.actionModalEditMode = true
      this.modalObject = Object.assign({}, partner)
      this.actionModal = true
    },
    closeActionModal () {
      this.actionModal = false
      this.reload()
    },
    handleRowClick (partner) {
      this.$router.push(`/partners/${partner.id}`)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
