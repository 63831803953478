var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('action-modal',{attrs:{"data":_vm.obj,"cancel-btn-text":"Annulla","loading-text":"Updating balance...","submit-title":("Correggi ricarica per " + (_vm.user.email)),"submit-success-message":"Credito rimosso con successo!","submit-btn-text":"Rimuovi credito","submit-promise-callback":_vm.revertTopUp,"fields":[{
        label: 'Importo',
        value: 'amount',
        rules: 'required|min:1',
        cols: '12',
        sm: '6'
    },
    {
        label: 'Canale',
        value: 'type',
        rules: 'required|min:1',
        cols: '12',
        sm: '6'
    }]},scopedSlots:_vm._u([{key:"field.amount",fn:function(ref){
    var errors = ref.errors;
    var label = ref.label;
    var classes = ref.classes;
return [_c('price-input',{class:classes,attrs:{"label":label,"outlined":"","error-messages":errors},model:{value:(_vm.obj.amount),callback:function ($$v) {_vm.$set(_vm.obj, "amount", $$v)},expression:"obj.amount"}})]}},{key:"field.type",fn:function(ref){
    var errors = ref.errors;
    var label = ref.label;
    var classes = ref.classes;
return [_c('v-select',{class:classes,attrs:{"label":label,"outlined":"","error-messages":errors,"items":_vm.revertTypes},model:{value:(_vm.obj.type),callback:function ($$v) {_vm.$set(_vm.obj, "type", $$v)},expression:"obj.type"}})]}}],null,false,3764062901),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }